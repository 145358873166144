<template>
  <div>
    <div class="wrapper">
      <div id="box" class="box">
        <div class="logo"><img class="logoImg" src="./assets/logo.png" /></div>
        <div class="menu">
          <div class="title" v-for="item in menus" :key="item.id">
            <template>
              <a
                class="item"
                :class="{ cur: item.cur }"
                @click="toggleMenu(item.id)"
                >{{ item.name }}</a
              >
            </template>
          </div>
        </div>
      </div>
    </div>
    <div id="index" class="main-banner">
      <div class="bannerBox">
        <img
          :class="{ show: item.showFlag, hide: item.hideFlag }"
          v-for="(item, index) in banners"
          :key="index"
          :src="item.img"
        />
        <div class="describe1">
          <span>医疗</span><span class="symbol">.</span><span>健康管理</span
          ><span class="describe1Light">一站式服务</span>
        </div>
        <div class="describe2">因大家 更健康 为大家 更便捷</div>
      </div>
      <div class="point">
        <div
          class="item"
          :class="{ show: item.showFlag, hide: item.hideFlag }"
          v-for="(item, index) in bannersPoints"
          :key="index"
          @click="showBannerItem(index)"
        ></div>
      </div>
    </div>
    <div id="service" class="service">
      <div class="title">
        <div class="text">我们的服务</div>
      </div>
      <div class="content">
        <div class="item">
          <div class="img">
            <img src="./assets/service/Internalmedicine.png" />
          </div>
          <div class="text">内科</div>
        </div>
        <div class="item left">
          <div class="img"><img src="./assets/service/surgical.png" /></div>
          <div class="text">外科</div>
        </div>
        <div class="item left">
          <div class="img"><img src="./assets/service/pediatric.png" /></div>
          <div class="text">儿科</div>
        </div>
        <div class="item left">
          <div class="img"><img src="./assets/service/regions.png" /></div>
          <div class="text">中医科</div>
        </div>
        <div class="item left">
          <div class="img"><img src="./assets/service/Orthopedics.png" /></div>
          <div class="text">骨伤科</div>
        </div>
        <div class="item">
          <div class="img"><img src="./assets/service/Physiatry.png" /></div>
          <div class="text">康复科</div>
        </div>
        <div class="item left">
          <div class="img">
            <img src="./assets/service/infantiletuina.png" />
          </div>
          <div class="text">小儿推拿科</div>
        </div>
        <div class="item left">
          <div class="img">
            <img src="./assets/service/Medicallaboratory.png" />
          </div>
          <div class="text">医学检验科</div>
        </div>
        <div class="item left">
          <div class="img"><img src="./assets/service/gynaecology.png" /></div>
          <div class="text">妇科</div>
        </div>
        <div class="item left">
          <div class="img">
            <img src="./assets/service/otherfamilies.png" />
          </div>
          <div class="text">其他科</div>
        </div>
      </div>
    </div>
    <div id="shop" class="shop">
      <div class="title">
        <div class="text">我们的门店</div>
      </div>
      <div class="content">
        <div class="item1">
          <img src="./assets/shop/shop1.png" />
        </div>
        <div class="item2">
          <img src="./assets/shop/shop2.png" />
        </div>
        <div class="item3">
          <img src="./assets/shop/shop3.png" />
        </div>
        <div class="item4">
          <img src="./assets/shop/shop4.png" />
        </div>
      </div>
    </div>
    <div id="about" class="about">
      <div class="title">
        <div class="text">关于我们</div>
      </div>
      <div class="content">
        <div class="banner">
          <img
            :class="{ show: item.showFlag, hide: item.hideFlag }"
            :src="item.img"
            v-for="(item, index) in aboutBanners"
            :key="index"
          />
          <div class="arrows">
            <div class="left">
              <img src="./assets/about/left.png" @click="leftHandle" />
            </div>
            <div class="right">
              <img src="./assets/about/right.png" @click="rightHandle" />
            </div>
          </div>
        </div>
        <div class="text">
          <div class="introduce">
            大家医生医疗连锁品牌成立于2017，我们励志打造成医生的创业平台和百姓的健康管家.我们的管理理念：因大家，更健康，为大家，更便捷.
            我们的服务理念：用心倾听，细心诊断，耐心解答，精心治疗，热心服务，衷心祝福.
          </div>
          <!-- <div class="more">了解更多</div> -->
        </div>
      </div>
    </div>
    <div id="cooperation" class="cooperation">
      <div class="title">
        <div class="text">合作单位</div>
      </div>
      <div class="content">
        <div class="item">
          <img src="./assets/cooperation/mndjk.png" />
        </div>
        <div class="item left"><img src="./assets/cooperation/cdyh.png" /></div>
        <div class="item left">
          <img src="./assets/cooperation/qjyxyx.png" />
        </div>
        <div class="item left">
          <img src="./assets/cooperation/glzjk.png" />
        </div>
        <div class="item top">
          <img src="./assets/cooperation/xmys.png" />
        </div>
        <div class="item left top">
          <img src="./assets/cooperation/yzl.png" />
        </div>
        <div class="item left top">
          <img src="./assets/cooperation/tcbj.png" />
        </div>
        <div class="item left top">
          <img src="./assets/cooperation/tft.png" />
        </div>
      </div>
    </div>
    <div id="contactMap" class="contactMap">
      <div class="title">
        <div class="text">联系我们</div>
      </div>
      <div class="content">
        <div class="map"><img src="./assets/footer/map.png" /></div>
      </div>
    </div>
    <div id="contactText" class="contactText">
      <div class="content">
        <div class="phone">
          <img class="icon" src="./assets/footer/phone.png" /><span class="text"
            >028-81730188</span
          >
        </div>
        <div class="name">大家健康管理（成都）有限公司</div>
        <div class="address">
          <img class="icon" src="./assets/footer/address.png" /><span
            class="text"
            >成都市武侯区天晖南街169号1310</span
          >
        </div>
        <div class="copyright">
          <span>CopyRight ©️ 2017-2023</span>
          <span style="margin-left: 20px"
            >大家医生|大家健康管理（成都）有限公司版权所有</span
          >
          <a
            style="margin-left: 20px"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            >蜀ICP备2022010362号-1</a
          >
          <img
            src="./assets/reference.png"
            style="margin-left: 20px; vertical-align: top"
          /><a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004754"
            target="_blank"
            >川公网安备 51019002004754号</a
          >
        </div>
        <div class="qrcode">
          <img src="./assets/footer/qrcode.png" /><span
            >扫描二维码了解更多</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banner1 from "./assets/banner/banner1.png";
import banner2 from "./assets/banner/banner2.png";
import banner3 from "./assets/banner/banner3.png";
import about1 from "./assets/about/about1.png";
import about2 from "./assets/about/about2.png";

export default {
  name: "App",
  components: {},
  data() {
    return {
      menus: [
        { name: "官网首页", cur: true, id: "index" },
        { name: "服务介绍", cur: false, id: "service" },
        { name: "门店展示", cur: false, id: "shop" },
        { name: "关于我们", cur: false, id: "about" },
        { name: "合作单位", cur: false, id: "cooperation" },
        { name: "联系我们", cur: false, id: "contactMap" },
      ],
      banners: [
        {
          img: banner1,
          showFlag: true,
          hideFlag: false,
        },
        {
          img: banner2,
          showFlag: false,
          hideFlag: true,
        },
        {
          img: banner3,
          showFlag: false,
          hideFlag: true,
        },
      ],
      bannersPoints: [
        { showFlag: true, hideFlag: false },
        { showFlag: false, hideFlag: true },
        { showFlag: false, hideFlag: true },
      ],
      aboutBanners: [
        { img: about1, showFlag: true, hideFlag: false },
        { img: about2, showFlag: false, hideFlag: true },
      ],
      count: 0,
      timer: null,
      menuTimer: null,
    };
  },
  mounted() {
    this.toggleMenu("index");
    window.addEventListener("scroll", this.scrollFn);
    this.timer = setInterval(() => {
      this.count = this.count + 1;
      if (this.count == this.banners.length) {
        this.count = 0;
      }
      this.banners.map((item, index) => {
        if (index === this.count) {
          item.showFlag = true;
          item.hideFlag = false;
        } else {
          item.showFlag = false;
          item.hideFlag = true;
        }
        return item;
      });
      this.bannersPoints.map((item, index) => {
        if (index === this.count) {
          item.showFlag = true;
          item.hideFlag = false;
        } else {
          item.showFlag = false;
          item.hideFlag = true;
        }
        return item;
      });
    }, 4500);
  },
  methods: {
    scrollFn() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let indexTop = this.$("#index").offset().top;
      let serviceTop = this.$("#service").offset().top;
      let shopTop = this.$("#shop").offset().top;
      let aboutTop = this.$("#about").offset().top;
      let cooperationTop = this.$("#cooperation").offset().top;
      let contactMapTop = this.$("#contactMap").offset().top;
      if (scrollTop >= indexTop && scrollTop < serviceTop) {
        this.changeMenu("index");
      }
      if (scrollTop >= serviceTop && scrollTop < shopTop) {
        this.changeMenu("service");
      }
      if (scrollTop >= shopTop && scrollTop < aboutTop) {
        this.changeMenu("shop");
      }
      if (scrollTop >= aboutTop && scrollTop < cooperationTop) {
        this.changeMenu("about");
      }
      if (scrollTop >= cooperationTop && scrollTop < contactMapTop) {
        this.changeMenu("cooperation");
      }
      if (scrollTop >= contactMapTop) {
        this.changeMenu("contactMap");
      }
    },
    changeMenu(id) {
      this.menus.map((item) => {
        if (id === item.id) {
          item.cur = true;
        } else {
          item.cur = false;
        }
        return item;
      });
    },
    toggleMenu(id) {
      clearTimeout(this.menuTimer);
      window.removeEventListener("scroll", this.scrollFn);
      this.menus.map((item) => {
        if (id === item.id) {
          item.cur = true;
        } else {
          item.cur = false;
        }
        return item;
      });
      if (id == "index") {
        this.$("html,body").animate({
          scrollTop: this.$(`#${id}`).offset().top - 200,
        });
      } else {
        this.$("html,body").animate({
          scrollTop: this.$(`#${id}`).offset().top,
        });
      }
      this.menuTimer = setTimeout(() => {
        window.addEventListener("scroll", this.scrollFn);
      }, 1000);
    },
    leftHandle() {
      this.aboutBanners[0].showFlag = true;
      this.aboutBanners[0].hideFlag = false;
      this.aboutBanners[1].showFlag = false;
      this.aboutBanners[1].hideFlag = true;
    },
    rightHandle() {
      this.aboutBanners[0].showFlag = false;
      this.aboutBanners[0].hideFlag = true;
      this.aboutBanners[1].showFlag = true;
      this.aboutBanners[1].hideFlag = false;
    },
    showBannerItem(count) {
      clearInterval(this.timer);
      this.count = count;
      this.banners.map((item, index) => {
        if (index === this.count) {
          item.showFlag = true;
          item.hideFlag = false;
        } else {
          item.showFlag = false;
          item.hideFlag = true;
        }
        return item;
      });
      this.bannersPoints.map((item, index) => {
        if (index === this.count) {
          item.showFlag = true;
          item.hideFlag = false;
        } else {
          item.showFlag = false;
          item.hideFlag = true;
        }
        return item;
      });
      this.timer = setInterval(() => {
        this.count = this.count + 1;
        if (this.count == this.banners.length) {
          this.count = 0;
        }
        this.banners.map((item, index) => {
          if (index === this.count) {
            item.showFlag = true;
            item.hideFlag = false;
          } else {
            item.showFlag = false;
            item.hideFlag = true;
          }
          return item;
        });
        this.bannersPoints.map((item, index) => {
          if (index === this.count) {
            item.showFlag = true;
            item.hideFlag = false;
          } else {
            item.showFlag = false;
            item.hideFlag = true;
          }
          return item;
        });
      }, 4500);
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
.wrapper {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
}
.box {
  margin: 0 auto;
  width: 1400px;
  height: 138px;
  z-index: 1000;
}
.box::after {
  display: table;
  content: "";
  clear: both;
}
.logo {
  float: left;
  width: 400px;
  height: 138px;
  line-height: 180px;
}
.logoImg {
  width: 237px;
  height: 54px;
}
.menu {
  float: left;
  width: 1000px;
  height: 138px;
}
.menu .title {
  float: left;
  position: relative;
  width: 166px;
  height: 138px;
}
.menu .title .item {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 130px;
  height: 40px;
  font-size: 22px;
  color: #333333;
  text-align: center;
  line-height: 40px;
  border-radius: 30px;
  cursor: pointer;
}
.menu .title .item.cur {
  background-color: #1b268f;
  color: #ffffff;
  font-size: 24px;
  box-shadow: 0 0 3px 3px rgb(27, 38, 143, 0.3);
}

.main-banner {
  position: relative;
  width: 100%;
  height: 942px;
}
.main-banner img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 3s;
}
.main-banner .bannerBox {
  position: relative;
  width: 100%;
  height: 100%;
}
.main-banner .bannerBox .describe1 {
  position: absolute;
  left: 26%;
  bottom: 23%;
  z-index: 20;
  font-size: 88px;
  color: #1b268f;
}
.main-banner .bannerBox .describe1 .symbol {
  position: relative;
  left: 0px;
  bottom: 20px;
  margin-left: 10px;
  margin-right: 20px;
}
.main-banner .bannerBox .describe1 .describe1Light {
  margin-left: 30px;
}
.main-banner .bannerBox .describe2 {
  position: absolute;
  left: 35%;
  bottom: 15%;
  font-size: 50px;
  color: #1b268f;
  z-index: 20;
}
.main-banner img.show {
  opacity: 1;
}
.main-banner img.hide {
  opacity: 0;
}

.main-banner .point {
  position: absolute;
  left: 45%;
  bottom: 10%;
  z-index: 10;
}
.main-banner .point .item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  float: left;
  margin-left: 30px;
  cursor: pointer;
}

.main-banner .point .item.show {
  background-color: #f89b3f;
}
.main-banner .point .item.hide {
  background-color: #cccccc;
}

.service {
  width: 1400px;
  height: 942px;
  padding-top: 50px;
  margin: 0 auto;
}
.service .title {
  height: 306px;
  width: 1400px;
  text-align: center;
  line-height: 306px;
}

.service .title .text {
  position: relative;
  font-size: 50px;
  color: #1b268f;
  font-weight: 900;
}
.service .title .text::after {
  position: absolute;
  display: block;
  content: "";
  width: 116px;
  height: 6px;
  background-color: #1b268f;
  left: 642px;
  bottom: 80px;
}
.service .content {
  width: 1400px;
  height: 590px;
}
.service .content::after {
  display: block;
  content: "";
  clear: both;
}
.service .content .item {
  float: left;
  text-align: center;
  width: 140px;
  height: 295px;
}
.service .content .item.left {
  margin-left: 175px;
}
.service .content .item .img {
  width: 140px;
  height: 140px;
  background-color: #9ab2cc;
  border-radius: 50%;
}
.service .content .item .img:hover {
  background-color: #f89b3f;
}
.service .content .item .img img {
  width: 100%;
  height: 100%;
}
.service .content .item .text {
  font-size: 28px;
  margin-top: 40px;
  color: #666666;
}
.shop {
  width: 1400px;
  height: 1210px;
  padding-top: 50px;
  margin: 0 auto;
}

.shop .title {
  width: 1400px;
  height: 306px;
  text-align: center;
  line-height: 306px;
}

.shop .title .text {
  position: relative;
  font-size: 50px;
  color: #1b268f;
  font-weight: 900;
}
.shop .title .text::after {
  position: absolute;
  display: block;
  content: "";
  width: 116px;
  height: 6px;
  background-color: #1b268f;
  left: 642px;
  bottom: 80px;
}
.shop .content {
  width: 1600px;
  height: 904px;
}
.shop .content::after {
  display: block;
  content: "";
  clear: both;
}
.shop .content .item1 {
  float: left;
  width: 808px;
  height: 330px;
}
.shop .content .item2 {
  float: left;
  margin-left: 37px;
  width: 555px;
  height: 256px;
}
.shop .content .item3 {
  float: left;
  margin-top: 33px;
  width: 808px;
  height: 341px;
}
.shop .content .item4 {
  float: left;
  margin-left: 37px;
  margin-top: -36px;
  width: 555px;
  height: 410px;
}
.shop .content img {
  width: 100%;
  height: 100%;
}
.about {
  width: 1400px;
  height: 858px;
  padding-top: 50px;
  margin: 0 auto;
}

.about .title {
  width: 1400px;
  height: 306px;
  text-align: center;
  line-height: 306px;
}

.about .title .text {
  position: relative;
  font-size: 50px;
  color: #1b268f;
  font-weight: 900;
}
.about .title .text::after {
  position: absolute;
  display: block;
  content: "";
  width: 116px;
  height: 6px;
  background-color: #1b268f;
  left: 642px;
  bottom: 80px;
}
.about .content {
  width: 1400px;
  height: 552px;
}
.about .content::after {
  display: block;
  content: "";
  clear: both;
}
.about .content .banner {
  position: relative;
  float: left;
  width: 700px;
  height: 480px;
}
.about .content .banner .arrows {
  transition: all 1s;
  opacity: 0;
}
.about .content .banner img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 2s;
}

.about .content .banner:hover .arrows {
  opacity: 1;
}

.about .content .banner img.show {
  opacity: 1;
}

.about .content .banner img.hide {
  opacity: 0;
}

.about .content .banner .left {
  position: absolute;
  width: 48px;
  height: 130px;
  background-color: #c3c3c3;
  left: 46px;
  top: 180px;
  cursor: pointer;
}
.about .content .banner .right {
  position: absolute;
  width: 48px;
  height: 130px;
  background-color: #c3c3c3;
  right: 46px;
  top: 180px;
  cursor: pointer;
}
.about .content .text {
  float: left;
  margin-left: 100px;
  width: 400px;
  height: 552px;
}
.about .content .text .introduce {
  width: 580px;
  height: 364px;
  color: #666666;
  font-size: 26px;
  line-height: 60px;
}
.about .content .text .more {
  width: 140px;
  height: 50px;
  font-size: 20px;
  background-color: #f89b3f;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  line-height: 50px;
  margin-left: 218px;
  margin-top: 30px;
}
.cooperation {
  width: 1400px;
  height: 900px;
  padding-top: 50px;
  margin: 0 auto;
}

.cooperation .title {
  width: 1400px;
  height: 306px;
  text-align: center;
  line-height: 306px;
}

.cooperation .title .text {
  position: relative;
  font-size: 50px;
  color: #1b268f;
  font-weight: 900;
}
.cooperation .title .text::after {
  position: absolute;
  display: block;
  content: "";
  width: 116px;
  height: 6px;
  background-color: #1b268f;
  left: 642px;
  bottom: 80px;
}
.cooperation .content {
  width: 1400px;
  height: 484px;
  margin: 0 auto;
}
.cooperation .content::after {
  display: block;
  content: "";
  clear: both;
}
.cooperation .content .item {
  float: left;
  width: 300px;
  height: 180px;
}
.cooperation .content .item.left {
  margin-left: 64px;
}
.cooperation .content .item.top {
  margin-top: 44px;
}
.cooperation .content .item img {
  width: 100%;
  height: 100%;
  border: 2px solid #cccccc;
}
.contactMap {
  margin: 0 auto;
  width: 1430px;
  height: 1100px;
  padding-top: 50px;
}
.contactMap .title {
  width: 1400px;
  height: 306px;
  text-align: center;
  line-height: 306px;
}

.contactMap .title .text {
  position: relative;
  font-size: 50px;
  color: #1b268f;
  font-weight: 900;
}
.contactMap .title .text::after {
  position: absolute;
  display: block;
  content: "";
  width: 116px;
  height: 6px;
  background-color: #1b268f;
  left: 642px;
  bottom: 80px;
}
.contactMap .content {
  width: 1430px;
}

.contactMap .content img {
  width: 100%;
  height: 100%;
}

.contactText {
  margin-top: 40px;
  width: 100%;
  height: 260px;
  background-color: #444444;
  color: #fff;
}
.contactText .content {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 1600px;
  height: 260px;
}
.contactText .content .phone {
  font-size: 34px;
  margin-top: 20px;
}
.contactText .content .phone .icon {
  width: 40px;
  height: 40px;
  vertical-align: middle;
}
.contactText .content .phone .text {
  margin-left: 40px;
  vertical-align: middle;
}
.contactText .content .name {
  margin-top: 24px;
  font-size: 30px;
}
.contactText .content .address {
  margin-top: 24px;
  font-size: 20px;
}
.contactText .content .address .icon {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}
.contactText .content .address .text {
  margin-left: 24px;
  vertical-align: middle;
}
.contactText .content .copyright {
  font-size: 18px;
  margin-top: 20px;
  margin-left: 40px;
}
.contactText .content .qrcode {
  position: absolute;
  text-align: center;
  width: 150px;
  height: 150px;
  top: 60px;
  right: 0;
}
.contactText .content .qrcode img {
  width: 100%;
  height: 100%;
}
.contactText .content .qrcode span {
  font-size: 12px;
  margin-top: 20px;
}
a {
  color: #fff;
  text-decoration: none;
}
</style>
